<template>
  <div class="app-container">
    <CrudTable entity="LocalProductCategory" :columns="columns" :permission-code="'LocalProductCategory'" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        {
          field: 'name',
          label: 'common.category'
        },
        {
          field: 'restaurantName',
          label: 'common.restaurantName'
        },
        { field: 'position', label: 'common.positionInCashDesk' }
      ]
    };
  }
};
</script>

<style scoped></style>
